<template>
  <div class="main-crm-container">
    <Sidebar
      :isOpened="isSidebarOpen"
      :isCloseBtnSwown="isSidebarCloseBtnShown"
      :options="sidebarOptions"
      :appOffsetWidth="appDiv.offsetWidth"
      @closeSidebar="toggleSidebar"
    />
    <div class="main-content">
      <div class="header-subscription" v-if="isAccountDisabled">
        <div class="header-title">
          Your Account has been disabled. To continue working,you need to
          <u @click="handleMoveToSubscriptions()"> pay for your account</u>
        </div>
      </div>
      <router-view @openSidebar="toggleSidebar" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Sidebar from '../common/sidebar/Sidebar.vue'
import MainHeader from '../common/header/MainHeader.vue'

import { UserSubscriptionStatus } from '../../utils/constants'

export default {
  components: {
    Sidebar,
    MainHeader,
  },
  data() {
    return {
      appDiv: null,
      isSidebarOpen: true,
      isSidebarCloseBtnShown: false,
      UserSubscriptionStatus: UserSubscriptionStatus,
      sidebarOptions: [
        'Clients',
        'Prospects',
        // 'Completed Sales',
        // 'Pipelines',
        'Reports',
        'Archive',
      ],
      // sublistOptions: {
      //   ClientsPipeline: 'Clients Pipeline',
      //   ProspectsPipeline: 'Prospects Pipeline',
      //   CompletedSales: 'Completed Sales',
      // },
    }
  },
  created() {
    this.appDiv = document.getElementById('app')
    this.handleResize() // Initial check
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    appDiv(newValue, oldValue) {
      if (newValue.offsetWidth <= 1024) {
        this.isSidebarOpen = false
        this.isSidebarCloseBtnShown = true
        return
      }

      if (newValue.offsetWidth > 1024) {
        this.isSidebarOpen = true
        this.isSidebarCloseBtnShown = false
        return
      }
    },
  },
  computed: {
    ...mapState(['currentUser']),
    isAccountDisabled() {
      const statuses = [
        UserSubscriptionStatus.NotStarted,
        UserSubscriptionStatus.Incomplete,
        UserSubscriptionStatus.Stopped,
      ]

      return statuses.includes(this.currentUser.subscription.status)
    },
  },
  methods: {
    handleMoveToSubscriptions() {
      this.$router.push({ name: 'Subscriptions' })
    },
    ...mapActions(['getActivePipeline']),
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    handleResize() {
      if (!this.appDiv) return

      if (this.appDiv.offsetWidth <= 1024) {
        this.isSidebarOpen = false
        this.isSidebarCloseBtnShown = true
      } else {
        this.isSidebarOpen = true
        this.isSidebarCloseBtnShown = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styleVars.scss';

.main-crm-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $mainBackgroundColor;

  .main-content {
    width: calc(100% - 240px);
    height: 100%;
    margin-left: 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll !important;

    .header-subscription {
      width: 100%;
      height: 44px;
      background-color: red;
      display: flex;
      align-items: center;
      text-align: center;
      z-index: 9999999;

      .header-title {
        color: $fontColorWhite;
        font-size: $fontSizeStandart;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        u {
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-crm-container {
    .main-content {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
